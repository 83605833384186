// @ts-check
import React from "react"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card, { CardTitle } from "../components/card"
import colors from "../styles/colors"
import Typography, { typographyComponents } from "../components/typography"
import DemoContext from "../contexts/demo"
import Link from "../components/link"

const HeroTitle = styled(CardTitle)`
  margin: 0 auto 0.9em;
  text-align: center;

  svg {
    display: inline-block;
    position: relative;
    height: 1.4em;
  }
`
const Subtitle = styled(typographyComponents.h6)`
  display: block;
  max-width: 650px;
  text-align: center;
  margin: 0 auto;
`
const Title = styled(CardTitle)`
  display: block;
  color: ${colors.primary};
  font-weight: normal;
  margin-bottom: 1em;
`
const Caption = styled(typographyComponents.bodyLarge)`
  font-weight: normal;
  a {
    color: inherit;
  }
  p {
    margin-bottom: 1.5em;
  }
`

const CopyCard = styled(Card)`
  max-width: 700px;
  margin: 0;
`

const MessageCard = styled(Card)`
  padding-top: 20px;
  padding-bottom: 20px;
`

const Message = () => {
  const { open } = React.useContext(DemoContext)
  return (
    <MessageCard variant="error" noMinHeight>
      <Typography variant="h4">CES 2020 is over!</Typography>
      <Caption>
        If you want to know more about our solution, feel free to{" "}
        <Link
          to="#"
          onClick={e => {
            e.preventDefault()
            open(e)
          }}
        >
          schedule a demo
        </Link>{" "}
        or <Link to="/contact-us/">contact us</Link>!
      </Caption>
    </MessageCard>
  )
}

const Together = styled.span`
  display: inline-block;
`

const SVGCES = styled.svg`
  width: 2.42em;
  height: 1.5em;
  top: 0.3em;
`
// prettier-ignore
const IllustrationCES = () => (
  <SVGCES viewBox="0 0 234 135">
    <path fill="#7EC6F2" d="M125.77 14.05l3.5 17.15-41.46 5.19-3.76-35.05 41.72 12.7zM202.56 125.47l.9-18.38-44.2-8.91 3.21 20.45 40.1 6.84z" />
    <path fill="#5A9DD7" d="M.56 47.36l50 86.9 111.91-15.63-.32-3.3-67.71-8.38-6.63-70.57L.56 47.36z" />
    <path fill="#4980BA" d="M129.27 31.2l30.71 66.16 2.5 21.27-68.03-11.68-6.64-70.57 41.46-5.18z" />
    <path fill="#DEE13F" d="M233.56.25L220.1 110.64l-16.66-2.43 3.34-69.53-81.01-24.63-1.18-5.67L233.56.25z" />
    <path fill="#78BB9C" d="M125.77 14.04l17.2 84.14 60.47 10.02 3.34-69.52-81.01-24.64z" />
    <path fill="#fff" d="M184.46 43.56c.72 0 1.31.26 1.83.78.5.51.76 1.13.76 1.86 0 .73-.26 1.36-.76 1.88-.5.52-1.11.77-1.83.77s-1.34-.26-1.83-.77a2.57 2.57 0 01-.76-1.88c0-.73.25-1.35.76-1.86.5-.52 1.12-.78 1.83-.78zm3.01 2.63c0-.83-.3-1.56-.88-2.15-.59-.6-1.3-.9-2.12-.9-.83 0-1.54.3-2.13.9a3 3 0 00-.87 2.15 3.02 3.02 0 003 3.08c.82 0 1.53-.3 2.12-.9a3 3 0 00.88-2.18zm-2.22-.66c0 .26-.11.44-.34.52-.13.06-.3.08-.55.08h-.53v-1.22h.5c.32 0 .55.04.7.13.15.08.22.25.22.49zm-2-1.02v3.37h.59v-1.33h.45c.31 0 .52.03.65.1.2.13.3.38.3.78v.27l.02.14.01.04h.55l-.02-.05-.03-.18v-.49a.9.9 0 00-.19-.52.8.8 0 00-.56-.33c.21-.04.38-.09.49-.17.2-.14.31-.35.31-.66 0-.43-.16-.71-.5-.85-.2-.08-.5-.12-.92-.12h-1.15M85.96 62.72c-4.08-4.08-10.1-6.66-17.4-6.66-11.5 0-19.02 8.49-19.02 20.73 0 12.57 8.17 20.73 19.56 20.73 7.3 0 12.56-2.15 17.94-6.55v14.07c-5.91 3.54-12.03 4.72-18.48 4.72-19.98 0-32.99-13.53-32.99-32.97 0-19.55 13.54-32.97 32.99-32.97 6.44 0 12.24 1.29 17.4 4.3v14.6M135.52 96.77v11.92H94.16V44.9h40.72v11.92h-27.3v13.97h25.04v11.7h-25.03v14.29h27.93zM141.6 91.61c4.83 3.66 10.2 6.56 17.29 6.56 5.48 0 10.31-1.94 10.31-7.2 0-4.84-3.97-6.66-10.63-8.6-12.79-3.54-18.05-8.9-18.05-19.65 0-12.13 9.45-18.9 21.27-18.9 7.2 0 12.46 1.82 16.97 4.72v13.43c-5.05-4.08-10.2-6.55-16.54-6.55-5.49 0-8.5 2.68-8.5 6.65 0 4.51 3.66 6.34 11.07 8.38 12.79 3.65 17.73 9.35 17.73 20.09 0 13.32-10.53 19.22-23.64 19.22-6.98 0-13.21-1.92-17.29-4.61V91.6" />
  </SVGCES>
)

// Still used in the CES 2020 page (kept for SEO)
const CESLine = () => (
  <>
    Makes{" "}
    <Together>
      <IllustrationCES aria-label="CES (Consumer Electronics Show)" /> work!
    </Together>
  </>
)

const CES2020Page = () => (
  <Layout bg="primary" newsletter={null}>
    <SEO title="Meet us at CES 2020" />
    <Card variant="primary">
      <HeroTitle variant="hero" as="h2">
        <CESLine />
      </HeroTitle>
      <Subtitle as="h1">
        Mavenoid automates tech&nbsp;support&nbsp;for&nbsp;any&nbsp;product.
        Meet&nbsp;us at&nbsp;CES&nbsp;Las&nbsp;Vegas.
        7–10&nbsp;January&nbsp;2020
      </Subtitle>
    </Card>

    <Message />

    <CopyCard variant="light">
      <Title variant="h3" maxWidth={550}>
        20,000 new products are launched at CES&nbsp;2020. Most of them are in
        the trash before&nbsp;2023.
      </Title>

      <Caption>
        <p>
          It’s wasteful. It’s expensive. And it’s unnecessary. Together we can
          make all consumer electronics work better, and longer.
        </p>
        <p>
          With Mavenoid, users can repair their broken products without wait.
          And as a manufacturer, you’ll be able support older products longer,
          at lower costs, while saving money and time in your support centers.
        </p>
        <p>
          We’ll be at CES 2020. If you’re launching a product or simply want to
          discuss better tech support, we want to meet you.
        </p>
      </Caption>
    </CopyCard>
  </Layout>
)

export default CES2020Page
